<!-- 智慧教学 -->
<template>
    <div>
        <KeepAliveRouterView />
    </div>
</template>
<script>
    export default {
        name: "SEdu"
    }
</script>
